/* General Styles */
.description {
    color: olive;
    background-color: #D4D6CF;
  }
  
  .outer-headings {
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .inner-headings {
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    font-size: 45px;
    text-transform: uppercase;
    overflow: hidden;
  }
  
  .inner-headings span {
    position: relative;
    color: black;
    animation: animation 10s ease infinite;
  }
  
  @keyframes animation {
    0%, 100% { top: 0; }
    20% { top: 0; }
    25% { top: -50px; }
    45% { top: -50px; }
    50% { top: -100px; }
    70% { top: -100px; }
    75% { top: -150px; }
    95% { top: -150px; }
  }
  
/* Increase specificity by targeting HTML element, class, and ID more precisely */
@media (min-width: 768px) and (max-width: 1007px) {
    .PageCard {
      flex-direction: column !important; /* Force column layout between 768px and 1007px */
    }
  }
  
  @media (max-width: 1007px) {
    .PageCard {
      flex-direction: column;
    }
    .PageCard .text-container, .PageCard .image-container {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  
  